import React from "react";
import { Box, Container, Heading, Text } from "theme-ui";
import { colors } from "../style_dependencies/variables";

const PageHeader = ({ data }) => {
  const { title, update } = data.frontmatter;

  return (
    <Box as="section" sx={styles.banner}>
      <Container sx={styles.container}>
        <Heading as="h1" sx={styles.container.heading}>
          {title}{" "}
        </Heading>
        <Text sx={{ fontSize: "18px" }}>Lastly updated on: {update}</Text>
      </Container>
    </Box>
  );
};

export default PageHeader;

const styles = {
  banner: {
    backgroundColor: colors.mainColor,
    textAlign: "center",
    pt: "160px",
    pb: "30px",
  },
  container: {
    heading: {
      fontSize: ["2.5rem", null, "3rem"],
      lineHeight: 1.25,
      color: "#02073E",
      fontWeight: 700,
      width: "100%",
      mx: "auto",
    },
  },
};
