import React from "react";
import { Box, Container, Text } from "theme-ui";

const TermsPage = ({ data }) => {
  const html = data.html;

  return (
    <Box as="section" sx={styles.banner}>
      <Container sx={styles.container}>
        <Text
          sx={styles.container.text}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Container>
    </Box>
  );
};

export default TermsPage;

const styles = {
  banner: {
    py: ["20px", null, null, null, "30px"],
    px: "10%",

    "@media screen and (max-width: 567px)": {
      px: "unset",
    },
  },
  container: {
    text: {
      fontSize: "18px",
    },
  },
};
