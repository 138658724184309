import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
import SEO from "components/seo";
import Layout from "components/layout";
import PageHeader from "components/page-header";
import TermsPage from "components/terms-page";
import { graphql } from "gatsby";

// Load other package css file
import "react-multi-carousel/lib/styles.css";
import "react-modal-video/css/modal-video.min.css";
import "rc-drawer/assets/index.css";

const seoData = {
  title: "Terms & Conditions | Fapshi",
  description:
    "The following terms in this Agreement relate to your use of All Fapshi Services. They contain guidelines on actions taken to secure your accounts, restricted activities, and more. We advise you read them carefully.",
  keywords:
    "fapshi terms, fapshi user terms, merchant terms, fapshi terms of service, fapshi terms and conditions, banned activities on fapshi, what i can sell on fapshi, fapshi terms of use, fapshi disputes, fapshi account security",
  pathname: "terms-and-conditions",
};

export default function Terms({ data }) {
  const termsData = data.allMarkdownRemark.nodes[1];

  return (
    <StickyProvider>
      <Layout>
        <SEO data={seoData} />
        <PageHeader data={termsData} />
        <TermsPage data={termsData} />
      </Layout>
    </StickyProvider>
  );
}

export const query = graphql`
  query allTerms {
    allMarkdownRemark(sort: { order: ASC, fields: id }) {
      nodes {
        frontmatter {
          title
          update
        }
        html
      }
    }
  }
`;
